
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
@import './wordpress.scss';
@import './wordpress-various.scss';

@import './mixins.scss';
@import 'animate.css/animate.min.css';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
body {
  overflow-x: hidden;
  font-weight: 300;
  // background: #00263e;
  font-variant-ligatures: common-ligatures;
  @include scrollbars();
}

html {
  scroll-behavior: smooth;
}

#__next {
  // overflow-x: hidden;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
// Masonry
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */

  margin-bottom: 30px;
}
.page-399 {
  section[class*='Banner_banner'] {
    @media all and (max-width: 500px) {
      h1 {
        font-size: 10vw;
        line-height: 1;
        padding-top: calc(50px + 15px);
      }
    }
  }
}
.page-52,
.page-1352 {
  div[class*='BannerStandard_bannerCont'] {
    @media (max-width: map-get($grid-breakpoints, 'md')) {
      background-position: left;
    }
  }
}
main {
  // margin-top: 70px;
}
.mt-12 {
  margin-top: 12px;
}
section[class*='Banner_banner'] {
  z-index: 1;
  position: relative;

  // > div,
  // video {
  //   height: calc(100vh + 150px);
  //   background-attachment: fixed;
  // }
}

.firstWrapper {
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  z-index: 1;
  overflow: visible;
  position: relative;
  padding: 80px 0 0;
  padding-bottom: 0;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  // min-height: 100vh;
  margin-top: -36px;
  @media (min-width: map-get($grid-breakpoints, 'xxl')) {
    width: 100%;
  }
}
.mainContent {
  overflow-x: hidden;
  overflow-y: visible;
  position: relative;
  margin: 0 auto;
  z-index: 3;
}
.heroEnd {
  height: 10px;
  width: 100%;
  display: block;
  position: relative;
  z-index: 99999;
  // background: green;
}

// Colors
$colors: #e43e30, #00263e, #eeeeee, #ffffff, #ffb259, #f4733f, #5d47c2, #3a8dde,
  #000000, #53389e, #e6e1fd;

$colorsHover: #c9291c, #00263e, #eeeeee, #ffffff, #ffb259, #f4733f, #5d47c2,
  #3a8dde, #000000, #53389e, #e6e1fd;

:root {
  @each $c in $colors {
    $i: index($colors, $c) - 1;
    --ast-global-color-#{$i}: #{$c};
    --a3-color-#{$i}: #{$c};
  }

  @each $c in $colorsHover {
    $i: index($colorsHover, $c) - 1;
    --a3-color-hover-#{$i}: #{$c};
  }
}
img:not(.no-br) {
  border-radius: 16px;
}
.br-radius {
  border-radius: 50px;
}
.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extrabold {
  font-weight: 800;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.text-m-black {
  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    color: #000 !important;
  }
}

.xxl {
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 49px;

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    font-size: 106px;
    line-height: 98px;
  }
}

h1,
.h1,
.xl2 {
  font-size: 72px;
  line-height: 90px;
  letter-spacing: -0.02em;
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 72px;
    line-height: 90px;
    letter-spacing: -0.02em;
  }
}

h2,
.h2,
.xl {
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -6%;
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 60px;
    line-height: 72px;
  }
}
.h2-54 {
  font-size: 31px;
  line-height: 38px;
  letter-spacing: -6%;
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 54px;
    line-height: 67px;
  }
}

h3,
.h3,
.lg {
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.6px;

  &.cta-text {
    font-size: 32px;
    line-height: 35px;
    letter-spacing: -2.04px;
  }
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    &.cta-text {
      font-size: 50px;
      line-height: 62px;
      letter-spacing: -3px;
    }
  }
  &.md {
    font-size: 26px;
    line-height: 32.5px;

    font-weight: 600;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 43px;
      line-height: 53px;
    }
  }
}

h4,
.h4,
.md {
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.02em;
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }
  &.h4-xxxl {
    font-size: 31px;
    line-height: 38.75px;
    // letter-spacing: -2px;
    font-weight: 600;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 40px;
      line-height: 50px;
    }
  }
  &.h4-39 {
    font-size: 26px;
    line-height: 29px;
    // letter-spacing: -2px;
    font-weight: 600;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 39px;
      line-height: 47px;
    }
  }
  &.h4-xxl {
    font-size: 21px;
    line-height: 27.51px;
    // letter-spacing: -2px;
    font-weight: 400;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 25px;
      line-height: 34px;
    }
  }
  &.h4-32 {
    font-size: 21px;
    line-height: 27.51px;
    // letter-spacing: -2px;
    font-weight: 400;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 32px;
      line-height: 41px;
    }
  }
}

h5,
.h5,
.sm {
  font-size: 26px;
  line-height: 32px;
  letter-spacing: -0.02em;
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 30px;
    line-height: 38px;
    letter-spacing: -0.02em;
  }
}

h6,
.h6,
.xs {
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.36px;
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
}

a {
  text-decoration: underline;
  color: inherit;
}

.sTitle {
  font-size: 30px !important;
  line-height: 38px !important;
  letter-spacing: -1.2px;
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 49px !important;
    line-height: 52px !important;
    letter-spacing: -1.96px;
  }
}

h2,
li {
  &.intro {
    font-size: 30px;
    line-height: 38px;
    letter-spacing: -2px;
    br {
      display: none;
    }
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 56px;
      line-height: 70px;
    }
    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      br {
        display: block;
      }
    }
  }
}

h2.intro {
  margin-bottom: 50px !important;
}

.big-text {
  font-size: 65px;
  font-weight: 500;
  @media only screen and (max-width: 1270px) and (min-width: 990px) {
    font-size: 40px;
  }
}

p,
li {
  font-size: 18px;

  &.xl {
    font-size: 20px;
    line-height: 30px;
  }

  &.lg {
    font-size: 18px;
    line-height: 28px;
  }

  &.md {
    font-size: 16px;
    line-height: 24px;
  }

  &.sm {
    font-size: 14px;
    line-height: 20px;
  }

  &.xs {
    font-size: 12px;
    line-height: 18px;
  }
  &.xl-xs {
    font-size: 16px;
    font-weight: 300;
    line-height: 135%;
    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      font-size: 32px;
    }
  }
  &.li-xxl {
    font-size: 21px;
    line-height: 27.51px;
    // letter-spacing: -2px;
    font-weight: 400;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 25px;
      line-height: 34px;
    }
  }
  &.li-xxxl {
    font-size: 21px;
    line-height: 27.51px;
    // letter-spacing: -2px;
    font-weight: 400;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 32px;
      line-height: 41px;
    }
  }
  &.p-xxxl {
    font-size: 22px;
    line-height: 27px;
    // letter-spacing: -2px;
    font-weight: 600;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 40px;
      line-height: 50px;
    }
  }
  &.p-23 {
    font-size: 21px;
    line-height: 27px;
    // letter-spacing: -2px;
    font-weight: 600;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 23px;
      line-height: 31px;
    }
  }
}
.core-image {
  img.ps-24 {
    padding-left: 24px;
  }
  img.mb-30 {
    margin-bottom: 30px;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      margin-bottom: 0;
    }
  }
}
.core-heading {
  &.heading-arrow {
    margin: 0 0 20px 0;

    align-items: flex-end;
    justify-content: space-between;
  }
}

.core-columns {
  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    align-items: center;
  }
  .custom-width-cols {
    display: flex;
    gap: 30px;
  }
  &.g-100 {
    > .custom-width-cols {
      gap: 30px;
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        gap: 100px;
      }
    }
  }
  &.g-170 {
    > .custom-width-cols {
      gap: 30px;
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        gap: 170px;
      }
    }
  }
  &.g-70 {
    > .custom-width-cols {
      gap: 30px;
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        gap: 70px;
      }
    }
  }

  .custom-width-cols:not(:has(> .keep-width-mobile)) {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      flex-direction: column;
    }
  }
  .no-container-cols {
    display: flex;
    gap: 50px;
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      flex-direction: column;
      gap: 30px;
      .pr-mobile {
        padding-right: 25px !important;
      }
      .pl-mobile {
        padding-left: 25px !important;
      }
    }
  }
  &.mw-592 {
    max-width: 592px;
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      max-width: 100%;
    }
  }
  &.width-fit {
    width: fit-content;
  }
  &.not-centered {
    .custom-width-cols {
      justify-content: flex-start !important;
    }
  }
  .core-column {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    &.border-top {
      padding-top: 20px;
    }
    &.col-link {
      a {
        text-decoration: none;
      }
    }
    &.last-item-bottom {
      a:last-child {
        @media (min-width: map-get($grid-breakpoints, 'lg')) {
          margin-top: auto;
        }
      }
    }
  }
  .core-column:not(:last-child):not(.keep-width-mobile) {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      margin-bottom: 20px;
    }
  }
  .core-column:not(.keep-width-mobile) {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

.section.small-pt {
  padding-top: 30px;
}

.section,
.no-container,
.a3-section {
  padding: 50px 0;
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    padding: 80px 0;
  }
}
.section > .section {
  padding: 0;
}

.core-video:not(.keep-ratio) {
  position: relative;
  padding-top: 56.25%;
  .core-video-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  .video-poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 16 / 9;
    z-index: 1;
  }
}

.video-br {
  .core-video-player {
    border-radius: 16px;
  }
}

.core-video-player {
  overflow: hidden;
  video {
    border-radius: 16px;
  }
}

.core-video,
.video-wrapper {
  .video-btn {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    z-index: 100;
    cursor: pointer;
  }
  .pause-btn {
    display: none;
  }
  &:hover {
    .pause-btn {
      display: block;
    }
  }
}

.slick-list {
  .slick-track {
    display: flex;
    padding: 8px 0px;
    // align-items: center;
    align-items: normal; // Removed for Series slider
    .slick-slide {
      // border-radius: 16px;
      overflow: hidden;
    }
  }
}

.series-wrapper {
  .series-cols {
    color: #fff;
    .core-column {
      @media (max-width: map-get($grid-breakpoints, 'lg')) {
        padding-right: 0;
      }
    }
  }
}
.core-cover:not(.keep-cover-mobile) {
  .cover-img {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      height: 285px !important;
      order: 1;
    }
  }
  .cover-content {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      position: relative;
      padding: 0 0 0 24px !important;
      margin-top: 30px;
      order: 2;
    }
  }
}

.core-cover {
  a {
    text-decoration: none;
  }
  position: relative;
  .cover-img {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      height: 366px !important;
      img {
        height: 100% !important;
      }
    }
    &:not(.no-br) {
      border-radius: 16px;
    }
  }

  .cover-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .cover-content {
    overflow-y: auto;
    max-height: 100%;
    position: absolute;
    padding: 50px;
    width: 100%;
    max-width: 100%;
    z-index: 2;

    &.top-right {
      top: 0;
      right: 0;
    }

    &.top-left {
      top: 0;
      left: 0;
    }

    &.bottom-right {
      bottom: 0;
      right: 0;
    }

    &.bottom-left {
      bottom: 0;
      left: 0;
    }
    &.center-center {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .core-heading {
      margin: 0;
    }
    img.absolute-bottom-right {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;
      @media (max-width: map-get($grid-breakpoints, 'lg')) {
        max-width: 50%;
      }
    }
  }
  &.no-padding {
    .cover-content {
      padding: 0;
    }
  }
}
.a3-section {
  background-size: cover !important;

  .core-columns {
    margin-bottom: 20px;
    .core-list {
      &.skills {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        li {
          padding: 7px 16px;
          border: 1px solid #000;
          border-radius: 60px;
          width: fit-content;
          margin: 0 8px 16px 0;
        }
      }
      &.white-list {
        color: #fff;
      }
    }
  }
}

%ctaCore {
  text-decoration: none;
  padding: 22px 42px;
  gap: 8px;
  border-radius: 60px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  font-weight: 800;
  display: inline-block;
}

.arrow,
.btn-arrow {
  &:after {
    content: '\e900';
  }
}

.link {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;

  &:after {
    font-family: var(--icons-font);
    transition: all 0.2s ease-in-out;
    display: inline-block;
    font-size: 0.7em;
    margin-left: 5px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:hover,
  &.hover {
    font-weight: 700;
    &.purple {
      color: #53389e !important;
      &.arrow:after {
        color: #53389e;
      }
    }
    &.arrow:after {
      transform: rotate(45deg);
    }
  }

  &[disabled],
  &.disabled {
    color: #d0d5dd;
    font-weight: 500;
    cursor: not-allowed;
    &:hover {
      color: #d0d5dd;
    }
  }
}

.cta {
  @extend %ctaCore;
  background-color: var(--a3-color-0);
  border-color: 1px solid var(--a3-color-0);
  color: #ffffff;
  &:hover,
  &.hover {
    background-color: var(--a3-color-hover-0) !important;
    border-color: 1px solid var(--a3-color-hover-0) !important;
    color: #ffffff;
  }
  &.ctaAgent3 {
    background-color: #ffffff;
    border-color: 1px solid var(--a3-color-0);
    color: var(--a3-color-0);
    &:hover {
      background-color: #d0d5dd !important;
    }
  }
  &[disabled],
  &.disabled {
    background-color: #d0d5dd;
    color: #ffffff;
    cursor: not-allowed;
    &:hover {
      background-color: #d0d5dd !important;
      color: #ffffff !important;
    }
  }
}

.cta-outline,
.is-style-outline {
  @extend %ctaCore;
  background-color: transparent;
  border: 1px solid var(--a3-color-1);
  color: var(--a3-color-1);
  cursor: pointer;
  &.hover-yellow:hover {
    background-color: #fffaeb;
    color: #dc6803 !important;
    border-color: #dc6803 !important;
  }
  &.hover-orange:hover {
    background-color: #fde9e1;
    color: #f4733f !important;
    border-color: #f4733f !important;
  }
  &.hover-purple:hover {
    background-color: #e6e1fd;
    color: #53389e !important;
    border-color: #53389e !important;
  }

  &:hover,
  &.hover {
    background-color: #f0f0f0;
    color: var(--a3-color-1);
  }

  &[disabled],
  &.disabled {
    background-color: transparent;
    border-color: #d0d5dd;
    color: #d0d5dd;
  }
}

.copyBtn {
  display: flex;
  align-items: center;
  border: 1px solid #d0d5dd;
  border-radius: 16px;
  background-color: transparent;
  padding: 10px 16px;
  cursor: pointer;
}
.hs-form {
  fieldset {
    max-width: 100% !important;
  }
}

.mainList {
  z-index: 10;
  position: relative;
  padding-top: 60px;
  @media (min-width: map-get($grid-breakpoints, 'xl')) {
    padding-top: 0;
    padding-left: 60px !important;
  }
}

.work-2219 #s-0d02fe08 .core-video {
  padding-top: 66.25%;
}

.tc-page {
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 10px 0;
  }
  p {
    margin: 5px 0;
  }
}

.margin-top-70 {
  margin-top: 70px;
}
// Cookiebot
#CybotCookiebotDialog {
  #CybotCookiebotDialogBody {
    padding-top: 16px !important;
  }
  .CybotCookiebotDialogBodyButton {
    border-radius: 16px;
    padding: 10px 18px !important;
    width: fit-content !important;
    border: none !important;
    font-size: 14px !important;
    font-weight: 800 !important;

    &#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
      background-color: var(--a3-color-0);
    }
  }

  #CybotCookiebotDialogBodyLevelButtons {
    margin-top: 16px;
  }
}
.excerpt {
  p {
    color: #0c2a31;
    font-size: 18px;
    line-height: 24px;
  }
}

.responsive-iframe {
  max-width: 100%;
  padding-top: 56.25%;
  position: relative;
  width: 100%;

  iframe {
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// ABM / SUB Pages Global Styles
.banner {
  padding-top: 80px;
  .arrow-link {
    text-decoration: none;
    margin-left: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #000;
    &::before {
      content: '<-';
      font-weight: 700;
      margin-right: 6px;
      font-size: 18px;
    }
  }
  h1 {
    margin: 20px 0;
  }
  p {
    color: #000;
  }
}

@media (max-width: map-get($grid-breakpoints, 'xl')) {
  br:not(.br) {
    display: none;
  }
}

.sec-1 {
  img {
    align-self: center;
  }
  .no-br {
    border-radius: 28px;
    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      border-radius: 0;
    }
  }

  .blocks-wrapper {
    margin: 0;
    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      margin: 0 -14px;
    }
    .custom-width-cols {
      justify-content: space-between !important;
    }
  }
  .core-column {
    &:last-child {
      .core-image {
        width: 100% !important;
        img {
          width: 100% !important;
        }
      }
    }
  }
}
// END ABM / SUB Pages Global Styles

.this-machine-theme {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 800;
    color: #0c2a31;
  }

  h1 {
    font-size: 40px;
    line-height: 95%;
    letter-spacing: -3px;
    img {
      @media (max-width: map-get($grid-breakpoints, 'xl')) {
        vertical-align: sub;
      }
    }
    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      font-size: 65px;
    }
  }
  h2 {
    font-size: 32px;
    line-height: 110%;
    letter-spacing: -0.72px;
    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      font-size: 50px;
      letter-spacing: -3px;

      &.quote {
        font-size: 65px;
      }
    }
  }
  h3 {
    font-size: 26px;
    line-height: 110%;
    letter-spacing: -0.72px;
    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      font-size: 32px;
      letter-spacing: -1.92px;
    }
  }
  p {
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.72px;
    font-weight: 400;
    color: #0c2a31;
    // color: #fff;
    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      font-size: 18px;
    }
    &.xl {
      font-size: 22px;
      font-weight: 300;
      // color: #fff;
    }

    &.intro {
      font-size: 28px;
      font-weight: 300;
      line-height: 135%;
      letter-spacing: -1.92px;
      @media (min-width: map-get($grid-breakpoints, 'xl')) {
        font-size: 32px;
      }
    }
    &.xl-xs {
      font-size: 16px;
      font-weight: 300;
      line-height: 135%;
      @media (min-width: map-get($grid-breakpoints, 'xl')) {
        font-size: 32px;
      }
    }
  }
  .filed {
    height: 80px;
  }
  .cta {
    letter-spacing: -0.72px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    width: max-content;
  }
  br:not(.br) {
    display: none;
  }
  .video-container {
    margin: 40px 0;
  }
  .core-video-player {
    video {
      margin-bottom: -6px;
    }
  }
  div[class*='firstWrapper'] {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  div[class*='Results'] {
    color: #0c2a31;

    div[class*='Results_value'] {
      font-weight: 700;
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        letter-spacing: -5.6px;
      }
    }
  }

  section[class*='banner'] {
    padding-top: 0 !important;
    div[class*='bannerCont'] {
      position: relative;
      padding-top: 75%;
      background-position: center center;
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        padding-top: 56.25%;
      }
    }

    div[class*='Banner_textContent'] {
      bottom: 40px;
      top: auto;
      transform: unset;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: #fff;
      }
      @media (min-width: map-get($grid-breakpoints, 'md')) {
        bottom: 90px;
        margin-left: 100px;
      }

      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        bottom: 170px;
        top: auto;
        transform: unset;
        margin-left: 130px;
      }
      @media (max-width: map-get($grid-breakpoints, 'md')) {
        p {
          margin-bottom: 0 !important;
          font-size: 14px;
        }
        h1 {
          font-size: 26px;
        }
      }
    }
  }
  @media (min-width: map-get($grid-breakpoints, 'xl')) {
    br {
      display: block;
    }
  }
  .core-column {
    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      padding: 0;
    }
  }
  .box-shadow {
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
  }

  .a3-section,
  .no-container,
  .section {
    padding: 30px 0;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding: 47px 0;
    }
  }
  div[class*='tm-first-section'] {
    padding-bottom: 30px;
    z-index: 2;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding-bottom: 50px;
    }
  }
  div[class*='mainContent'] {
    margin-top: -20px;
  }
  .firstWrapper {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      padding: 20px 0 0;
    }
  }
  .ms-50 {
    margin-left: 50px;
  }
  .custom-width-cols {
    @media (max-width: map-get($grid-breakpoints, 'md')) {
      gap: 0;
    }
  }
  .g-100,
  .g-70,
  .g-170 {
    .custom-width-cols {
      @media (max-width: map-get($grid-breakpoints, 'lg')) {
        gap: 0;
      }
    }
  }
  .no-mb-mobile {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      margin-bottom: 0 !important;
    }
  }
  .no-padding-mobile {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      padding: 0 !important;
    }
  }
  .p-mobile-40 {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  .tm-columns {
    h2 {
      @media (max-width: map-get($grid-breakpoints, 'lg')) {
        margin-bottom: 0 !important;
      }
    }
    .cta {
      @media (max-width: map-get($grid-breakpoints, 'lg')) {
        margin: 0 !important;
      }
    }
  }
  div[class*='HSForm_form'] {
    border: none;
    padding: 0;
    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      padding: 0 15px 0;
    }
    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      max-width: 80%;
    }
    margin: 0 auto;
    input,
    textarea {
      border: none;
      border-bottom: 1px solid #fff;
      border-radius: 0;
      background-color: transparent;
      outline: none;
      color: #fff;
      font-size: 24px;
      padding: 10px 7px;
      height: 48px;
      font-family: 'Manrope', sans-serif;
    }
    textarea {
      font-family: 'Manrope', sans-serif;
    }

    fieldset {
      position: relative;
      display: flex;
      justify-content: space-between;
    }
    .hs-form-field {
      width: 100%;
    }
    .hs_firstname,
    .hs_lastname {
      width: 48% !important;
    }
    .hs_message {
      height: 78px;
    }
    .input {
      padding-bottom: 10px;
      position: relative;
      margin-top: 0;
      margin-right: 0px !important;
    }
    .hs-error-msg {
      font-size: 15px;
      position: absolute;
      bottom: -2px;
    }
    .br-radius-video {
      video {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
      }
    }
    input[type='submit'] {
      margin-top: 0;
      font-family: inherit;
      letter-spacing: -0.72px;
    }
    label:not(.hs-form-booleancheckbox-display, .hs-error-msg) {
      display: none;
    }

    .hs-form-booleancheckbox-display span > p {
      color: #fff;
      font-size: 13px;
      letter-spacing: normal;
    }
    .hs-form-required {
      color: #fff;
    }

    .hs_error_rollup {
      .hs-main-font-element {
        bottom: -20px;
        position: relative;
        display: none;
      }
    }
    .actions {
      text-align: center;
    }
    *[class*='hs-form-booleancheckbox'] label {
      align-items: start;
    }
    *[class*='hs-form-booleancheckbox'] label input[type='checkbox'] {
      appearance: none;
      border: 1px solid #fff;
      border-radius: 2px;
      background: transparent;
      width: 39px !important;
      height: 20px !important;
      margin-left: 10px;
      margin-top: 5px;

      &:checked {
        position: relative;
        &:before {
          content: '\2713';
          color: #fff;
          display: block;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    .hs-fieldtype-booleancheckbox {
      padding-bottom: 20px;
      position: relative;
      .hs-error-msg {
        bottom: 0;
        position: absolute;
      }
    }
    input[type='submit'] {
      cursor: pointer;
      padding: 22px 42px;
    }
    ::placeholder {
      color: #fff;
      font-size: 22px;
      line-height: none;
      left: 6px;
      font-family: var(--root-font);
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: #fff;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
      font-size: 24px;
      padding-left: 4px;
    }
  }
}
// end this-machine-theme

body.page-3722 {
  div[class*='navBar'] {
    div[class*='hamburger'] {
      display: none;
    }
    div[class*='mobileMenu'] {
      display: none;
    }
    div[class*='row'] {
      div[class*='links'] {
        background: none;
      }
      div[class*='col-lg-2'] {
        display: none !important;
      }
    }
  }
  div[class*='footer'] {
    div[class*='overlay'] {
      display: none !important;
    }
    div:has(video) {
      display: none !important;
    }
    div[class*='text'] {
      background-color: #0c2a31;
    }
    div[class*='content'] div:not([class*='logos']),
    h2 {
      display: none;
    }
  }
}

body.page-2894 {
  div[class*='bannerCont'] {
    &::before {
      content: '';
      position: absolute;
      background-color: rgba($color: #000000, $alpha: 0.05);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
body.page-2819,
body.page-3005 {
  .mainContent {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      margin-top: 34.43%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url('/images/tm-bg1.png');
      z-index: -1;
      background-size: cover;
      pointer-events: none;
    }
  }
}

.tc-page ul {
  list-style: none;
}

.tc-page ul {
  list-style: none;
}

.tabs-slide-enter {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.tabs-slide-enter-active {
  opacity: 1;
  height: auto;
  transition: opacity 300ms, height 300ms;
}

.tabs-slide-exit {
  opacity: 1;
  height: auto;
}

.tabs-slide-exit-active {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 300ms, height 300ms;
}

.tabs-slide-content {
  margin-bottom: 20px; /* Adjust as needed */
}
.box {
  border-radius: 6px;
  padding: 24px 12px;
  margin-bottom: 24px;
  .blocks-wrapper {
    > *:last-child {
      margin-bottom: 0;
      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.quote-box {
    border: 3px solid #eef4ff;
    .quote-text {
      font-size: 24px;
      line-height: 30px;
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        font-size: 38px;
        line-height: 48px;
      }
    }
  }
}

.withToc {
  font-weight: 400;
  ol,
  ul {
    margin-top: 0;
  }
  ol,
  ul {
    padding-left: 16px;
    li {
      margin-bottom: 8px;
    }
  }
  // p {
  //   margin-bottom: 24px;
  // }

  a {
    color: #328ee7;
  }
}

#s-abm-at-scale {
  // .blocks-wrapper > .py-5 {
  //   padding: 0 !important;
  // }
  .core-column {
    display: flex;
    align-items: center;
  }
}

.has-large-font-size {
  line-height: 1.1;
}

.vs-columns {
  .vs-title {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-width-cols {
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      gap: 0;
    }
  }
  .custom-width-cols {
    > :nth-child(2) {
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        width: 100px !important;
      }
    }
  }
  .core-list {
    ul {
      border-radius: 16px;
    }
  }
}

div[class*='DefaultAsset_content__'] .r-p {
  margin-bottom: 24px;
}

div[class*='DefaultAsset_content__'] .r-p + .py-5 {
  padding-top: 0 !important;
}

div[class*='DefaultAsset_content__'] .r-p br {
  display: block !important;
}
img.fullWidth {
  width: 100% !important;
}

.this-machine-box {
  height: 500px;
  .core-cover {
    position: relative;
    .cover-content {
      .bottom-left {
        top: 0;
      }
    }
  }
  .cover-img {
    height: 500px !important;
    // position: absolute;
  }
}

.home {
  .custom-width-cols {
    align-items: center;
  }
}
